@use '@angular/material' as mat;
@use './assets/theme.scss' as theme;
@use './assets/typography' as typo;

/* Typography variables*/
$title-font-size: mat.font-size(typo.$custom-typography, 'title');
$title-font-weight: mat.font-weight(typo.$custom-typography, 'title');
$title-line-height: mat.line-height(typo.$custom-typography, 'title');

$headline-font-size: mat.font-size(typo.$custom-typography, 'headline');
$headline-font-weight: mat.font-weight(typo.$custom-typography, 'headline');
$headline-line-height: mat.line-height(typo.$custom-typography, 'headline');

/* Remove increment from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  min-height: 100vh;
  height: 100vh;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: mat.font-size(typo.$custom-typography, 'body-1');
  color: #2c3439;
}

.whiteBackground {
  background-color: white !important;
  color: black !important;
  border-radius: 10px !important;
}

.mat-dialog-container {
  padding: 0px !important;
  overflow-y: hidden !important;
}

.subtext {
  color: #575757;
}

.field-info-icon {
  color: #2b6da3;
  font-size: 15px !important;
  margin-left: 6px;
  margin-top: 2px;
}

.ofo-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $headline-font-size;
  font-weight: $headline-font-weight;
  line-height: $headline-line-height;
  margin-bottom: 1rem;
  color: #2c3439;
}

.secondary-header {
  font-size: 18px;
  font-weight: 500;
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.ofo-grid-title {
  font-weight: 500;
  font-size: 24px;
  line-height: $title-line-height;
  color: #2c3439;
}

.ofo-dialog-content {
  max-height: calc(100vh - 290px);
  overflow-y: auto;
}

.mat-card {
  padding: 16px;
  background: #f8f9fa;
  margin-bottom: 1em;

  .mat-card-title {
    font-size: 24px;
    font-weight: 700;
    color: #2c3439;
    line-height: 24px;
    padding-bottom: 10px;
  }

  .mat-card-subtitle {
    color: #646464;
    font-weight: 400;
  }
}

.mat-tab-labels {
  gap: 30px;
}

.mat-tab-label {
  opacity: 1 !important;
  padding: 0 !important;
  justify-content: left !important;
  min-width: fit-content !important;
}

.mat-tab-link {
  opacity: 1 !important;
  color: #575757 !important;
}

.mat-tab-label-active {
  font-weight: 500 !important;
}

app-list th.mat-header-cell {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  // Grayscale/900
  color: #2c3439 !important;
}

app-filter mat-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  // Grayscale/900
  color: #2c3439;
}

app-dialog mat-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  // Grayscale/900
  color: #2c3439;
}

app-dialog mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper {
  padding-bottom: 0.6em;
}

app-dialog
  mat-form-field.mat-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix {
  padding: 0.7em 0px;
  border-top-width: 0.2em;
}

app-dialog
  mat-form-field.mat-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix
  > span.mat-form-field-label-wrapper {
  top: -1.5em;
}

app-dialog
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}

// To deal with Angular adding padding 24px to first cell
// which causes 3 dot menu to be pushed to the right
// https://github.com/angular/components/issues/11232
app-list th.mat-headercell:first-of-type,
app-list td.mat-cell:first-of-type,
app-list td.mat-footer-cell:first-of-type {
  padding-left: 0px;
}

.mat-row:hover {
  background-color: #f8f8f8;
}

.ofo-mat-panel-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  // Grayscale/900
  color: #333333;
}

.ofo-required:after {
  content: ' *';
  color: red;
}

.ofo-mat-form-field-wrapper {
  margin: 0px;
}

.ofo-mat-form-field-wrapper {
  font-size: 12px;
}

.ofo-mat-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  // Grayscale/900
  color: #616161;
}

.mat-flat-button {
  font-weight: 400 !important;
  color: #f8f9fa !important;
  min-width: fit-content !important;
  border-radius: 8px !important;
  height: 48px;
  margin-left: 16px;
}

.mat-stroked-button {
  font-weight: 400 !important;
  min-width: fit-content !important;
  border-radius: 8px !important;
  height: 48px;
  border-width: 1px !important;
  margin-left: 16px;
}

.page-action-button {
  margin-left: 16px !important;
}

// .main-container {
//   padding: 24px;
// }

.dashboard-container {
  box-shadow: 0 2px 6px rgba(116, 116, 116, 0.25);
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 18px;
}

.ofo-grid-title-sub-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #575757;
}

.page-actions {
  align-items: baseline;
  justify-content: space-between;
}

// style option dropdown
.font-option {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 14px;
}

/*
Colors
 */
$primary-color: mat.get-color-from-palette(theme.$primary-palette);
$info-color: #428be1;
$info-background-color: #e1f6fd;
$success-color: #3dbc59;
$success-background-color: #d2fbd6;
$error-color: #ee2d2d;
$error-background-color: #fee0e0;
$warning-color: #f4762f;
$warning-background-color: #f9e2d2;
$new-color: #d9a63b;
$new-background-color: #fef5cf;
$comment-color: #e906c4;
$comment-background-color: #fff2fb;

%primary-color {
  color: $primary-color;
}

%error-color {
  color: $error-color;
}

%info-color-pair {
  color: $info-color;
  background-color: $info-background-color;
}

%success-color-pair {
  color: $success-color;
  background-color: $success-background-color;
}

%error-color-pair {
  color: $error-color;
  background-color: $error-background-color;
}

%warning-color-pair {
  color: $warning-color;
  background-color: $warning-background-color;
}

%new-color-pair {
  color: $new-color;
  background-color: $new-background-color;
}

%comment-color-pair {
  color: $comment-color;
  background-color: $comment-background-color;
}

span,
div {
  .info {
    color: $info-color;
  }

  .success {
    color: $success-color;
  }

  .error {
    color: $error-color;
  }

  .warning {
    color: $warning-color;
  }

  .new {
    color: $new-color;
  }

  .comment {
    color: $comment-color;
  }
}

// Color pairs
.mat-flat-button {
  &.info {
    @extend %info-color-pair;
  }

  &.success {
    @extend %success-color-pair;
  }

  &.error {
    @extend %error-color-pair;
  }

  &.warning {
    @extend %warning-color-pair;
  }

  &.new {
    @extend %new-color-pair;
  }

  &.comment {
    @extend %comment-color-pair;
  }
}

/*
Typography
Extra typography classes that are not in Angular Material Typography.
 */
.bold-18 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
}

.medium-18 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
}

.regular-18 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}

// global style for anchors
a,
a:visited,
a:hover,
a:active {
  color: $primary-color;
  text-decoration: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  border-radius: 6px;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.form-wrapper {
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  height: 38px;
  align-items: center;
  justify-content: center;
  padding: 1px 8.5px;
}

.error {
  border: 2px solid $error-color !important;
}

.section-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #212121;
}

.font-option {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 14px;
}

.item {
  cursor: pointer;
}

td {
  padding-right: 12px !important;
}

table {
  position: relative !important;
  border-collapse: collapse;
}

th {
  background: white !important;
  position: sticky !important;
  top: -25px !important;
  z-index: 1;
}

#launcher-frame {
  max-width: 90px !important;
}

.mat-header-row {
  z-index: 1000;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgb(255 255 255) !important;
  border: none !important;
}

.currency-align {
  text-align: end !important;
}

mat-error {
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.ng-select .ng-select-container {
  border: none !important;
}

// adds breaks to the mat-checkboxes with long labels
.mat-checkbox-layout {
  white-space: normal !important;
}

// rather than center the checkbox, put the checkbox in the first line
.mat-checkbox-inner-container {
  margin-top: 3px !important;
}
// Style for mat error
.error-message {
  font-size: 13px;
  padding-top: 10px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.selectPanelClass {
  margin-top: 30px !important;
}

.no-records-found {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.mat-select-arrow-wrapper {
  height: 0px !important;
}

.form-field-label {
  font-size: 14px;
  font-weight: 500;
  color: #212121;
}
